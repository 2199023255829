<template>
  <div @click.stop class="popup_box">
    <div class="popup_content _app_box">
      <div class="popup_box">
        <div class="Main">
          <div class="Main_box">
            <router-view />
          </div>
          <div class="Main_tab">
            <div
              @click="tabCheck(index)"
              class="Main_tab_item"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <SvgIcon
                :name="item.svg"
                class="Main_tab_item_svg"
                :color="item.check ? tabItemColorS : tabItemColor"
              />
              <div
                :style="`color: ${item.check ? tabItemColorS : tabItemColor}`"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_app_box_close" @click="closeSdk">
        <SvgIcon name="back" class="_app_box_close_svg" color="#8d8d8d" />
        <div>{{ $t("main.exit") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      token: localStorage.getItem("token"),
      tabItemColor: "#8d8d8d", // tab默认颜色
      tabItemColorS: "#f25200", // tab 选中颜色
      tabs: [
        // { name: "优惠券", check: false, path: "/main/coupon", svg: "coupon" },
        // { name: "礼包", check: false, path: "/main/gift", svg: "gift" },
        // { name: "商城", check: false, path: "/main/shop", svg: "shop" },
        // { name: "VIP", check: false, path: "/main/vip", svg: "vip" },
        { name: this.$t("main.my"), check: false, path: "/main/my", svg: "my" },
      ],
    };
  },
  components: {},
  mounted() {
    this.getUserInfo();
    this.tabItemColorS = this.$config.primary_color;
    this.tabs.forEach((item, index) => {
      if (this.$route.path == item.path) {
        this.tabCheck(index);
      }
    });
  },
  methods: {
    tabCheck(key) {
      this.tabs.forEach((item) => {
        item.check = false;
      });
      this.tabs[key].check = true;
      this.$router.push(this.tabs[key].path);
    },
    closeSdk() {
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      localStorage.removeItem("iframeURL");
      this.$store.state.guest = true;
      this.$store.state.token = "";
      this.$store.state.iframeURL = "";
      this.$router.push({
        path: "/login",
        query: { game_id: localStorage.getItem("game_id") },
      });
      this.$store.commit("openSdk", false);
    },
    getUserInfo() {
      let params = {
        game_id: this.$store.state.game_id,
        userid: localStorage.getItem("userid"),
        token: localStorage.getItem("token"),
      };
      this.$http.post("/apis/h5/getUserInfo", params).then((res) => {
        if (res.code === 200) {
          //调用vuex的赋值 可能加个storage
          // console.log(res.data);
          this.$store.state.userInfo = res.data;
          // this.$store.state.iframeURL = "https://www.yysls.cn/m/?from=nietop";
        } else {
          if (res.code != 206) {
            this.$Msg(res.msg);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.Main_box {
  flex: 1;
  width: 100%;
  overflow: hidden;
}
.Main_tab {
  position: relative;
  z-index: 1;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  padding: 0 0.1rem;
  width: 100%;
  box-shadow: 0 0 5px #ddd;
  box-sizing: border-box;
}
.Main_tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0;
  width: 25%;
  font-size: 0.25rem;
}
.Main_tab_item_svg {
  width: 0.4rem;
  height: 0.4rem;
}
._app_box_close {
  cursor: pointer;
}
</style>
