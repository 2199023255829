import { createI18n } from 'vue-i18n'

let i18nList = ['ch', 'vn'] // 语言的key
let i18nVal = ''
if (localStorage.getItem('i18n')) { // 之前有设置过语言
	i18nList.forEach(item => {
		if (localStorage.getItem('i18n') == item) { // 验证缓存的语言key是否与本地一致
			i18nVal = item
		}
	})
}

const i18n = createI18n({
	locale: i18nVal ? i18nVal : 'vn', //默认显示的语言
	messages: {
		ch: require('./_ch.js'), // 中文
		vn: require('./_vn.js') // 越南
	}
})
export default i18n
