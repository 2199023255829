<template>
  <div class="login" @click.stop>
    <div class="header">
      <Tabbar :tab="tab" @change="tabChange" />
    </div>
    <div style="width: 100%">
      <!-- 账号登录 -->
      <div class="main" v-if="tabIndex == 0">
        <div class="cell">
          <div class="cell_input">
            <input
              type="text"
              v-model="userinfo"
              :placeholder="$t('login.accountPlaceholder')"
            />
          </div>
        </div>
        <div class="login_code">
          <div class="cell">
            <div class="cell_input">
              <input
                type="password"
                v-model="password"
                :placeholder="$t('login.passwordPlaceholder')"
              />
            </div>
          </div>
        </div>
        <div class="forget_password" @click="handleForget">
          {{ $t("login.forgetPassword") }}
        </div>
        <div class="submit_btn" @click="confirm()">{{ $t("login.login") }}</div>
        <div class="login_btn" @click="register()">
          {{ $t("login.register") }}
        </div>
        <div class="another_way">
          <div class="way_text">{{ $t("login.other") }}</div>
          <div class="way">
            <ul class="list-action">
              <li>
                <FbLogin :fb_appid="fb_appid" />
              </li>
              <li>
                <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
              </li>
              <li>
                <img src="@/assets/img/guest.png" @click="getGuestLogin" />
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="read">
          <div>
            <input
              type="checkbox"
              class="checkbox"
              v-model="checkbox"
              name="myCheckbox"
            />
          </div>
          <div class="read_text">我已阅读并同意《用户协议》</div>
        </div> -->
      </div>
      <!-- 验证码登录 -->
      <div class="main" v-if="tabIndex == 1">
        <div class="cell">
          <div class="cell_input">
            <input
              type="email"
              v-model="email"
              :placeholder="$t('login.emailPlaceholder')"
            />
          </div>
        </div>
        <div class="login_code">
          <div class="cell">
            <div class="cell_input">
              <input
                type="text"
                v-model="code"
                :placeholder="$t('login.codePlaceholder')"
              />
            </div>
          </div>
          <div @click="getCode()" class="login_code_btn">
            {{ codeTime > 0 ? codeTime : $t("login.getCode") }}
          </div>
        </div>
        <div class="submit_btn" @click="confirm()">{{ $t("login.login") }}</div>
        <div class="login_btn" @click="register()">
          {{ $t("login.register") }}
        </div>
        <div class="another_way">
          <div class="way_text">{{ $t("login.other") }}</div>
          <div class="way">
            <ul class="list-action">
              <li>
                <FbLogin :fb_appid="fb_appid" />
              </li>
              <li>
                <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
              </li>
              <li>
                <img src="@/assets/img/guest.png" @click="getGuestLogin" />
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="read">
          <div>
            <input
              type="checkbox"
              class="checkbox"
              v-model="checkbox"
              name="myCheckbox"
            />
          </div>
          <div class="read_text">我已阅读并同意《用户协议》</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Tabbar from "@/components/tabbar.vue";
import FbLogin from "../../components/FbLogin.vue";
import GoogleLogin from "../../components/GoogleLogin.vue";
export default {
  components: { Tabbar, FbLogin, GoogleLogin },
  data() {
    return {
      tabIndex: 0,
      codeTime: 0,
      checkbox: false,
      userinfo: "",
      password: "",
      email: "",
      code: "",
      tab: [
        { name: this.$t("login.accountLogin"), check: true },
        { name: this.$t("login.codeLogin"), check: false },
      ],
    };
  },
  created() {
    this.$store.state.game_id = this.$route.query.game_id;
    if (this.$store.state.game_id) {
      localStorage.setItem("game_id", this.$store.state.game_id);
    } else {
      this.$store.state.game_id = localStorage.getItem("game_id") || "";
    }
    if (!this.$store.state.guest) {
      this.getGuestLogin();
    }
    // window.fbq('track', 'ViewContent');
  },
  mounted() {
    setTimeout(() => {
      this.$refs.GoogleLogin.initGoogle();
    }, 150);
  },
  computed: {
    fb_appid() {
      return this.$store.state.fb_appid;
    },
    client_id() {
      return this.$store.state.client_id;
    },
  },
  methods: {
    tabChange(e) {
      this.tabIndex = e;
      setTimeout(() => {
        this.$refs.GoogleLogin.initGoogle();
      }, 150);
    },
    getCode() {
      if (this.email === "") {
        this.$Msg(this.$t("login.emailCheck"));
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        game_id: this.$store.state.game_id,
        email: this.email,
      };
      this.$http.post("/apis/h5/sendRegisterCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    handleForget() {
      this.$router.push("login/setPassword");
    },
    confirm() {
      // if ( this.password ) {
      //   this.$router.push("/");
      // } else {
      //   this.$Msg("请输入正确的账号或密码");
      // }
      if (this.tabIndex == 0) {
        if (this.userinfo && this.password) {
          let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
          let params = {
            game_id: this.$store.state.game_id,
            username: this.userinfo,
            passwd: this.password,
            device_id: webUUID,
          };
          this.$http.post("/apis/h5/normalLogin", params).then((res) => {
            if (res.code === 200) {
              switch (Number(params.game_id)) {
                case 121:
                  Adjust.trackEvent({
                    eventToken: "wjzkta",
                  });
                  break;
                default:
                  Adjust.trackEvent({
                    eventToken: "4a7k78",
                  });
                  break;
              }
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userid", res.data.userid);
              localStorage.setItem("iframeURL", res.data.login_url);
              localStorage.removeItem("guestInfo")
              this.$store.state.token = res.data.token;
              this.$store.state.iframeURL = res.data.login_url;
              // this.$router.push("/main");
              this.$router.push("/main/my");
              this.$store.state.sdkShow = false;
              this.$store.commit("openSdk", false);
            } else {
              this.$Msg(res.msg);
            }
          });
          // if (this.checkbox) {

          // } else {
          //   this.$Msg("请先阅读《用户协议》");
          // }
        } else {
          this.$Msg(this.$t("login.accountCheck"));
        }
      } else {
        if (this.email && this.code) {
          let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
          let params = {
            game_id: this.$store.state.game_id, //游戏id
            username: this.email, //邮箱账号
            device_id: webUUID,
            auth_code: this.code, //短信验证码
            reg_type: 2,
          };
          this.$http.post("/apis/h5/normalReg", params).then((res) => {
            if (res.code === 200) {
              switch (Number(params.game_id)) {
                case 121:
                  Adjust.trackEvent({
                    eventToken: "wjzkta",
                  });
                  break;
                default:
                  Adjust.trackEvent({
                    eventToken: "4a7k78",
                  });
                  break;
              }
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userid", res.data.userid);
              localStorage.setItem("iframeURL", res.data.login_url);
              localStorage.removeItem("guestInfo")
              this.$store.state.token = res.data.token;
              this.$store.state.iframeURL = res.data.login_url;
              // this.$router.push("/main");
              this.$router.push("/main/my");
              this.$store.state.sdkShow = false;
              this.$store.commit("openSdk", false);
            } else {
              this.$Msg(res.msg);
            }
          });
          // if (this.checkbox) {

          // } else {
          //   this.$Msg("请先阅读《用户协议》");
          // }
        } else {
          this.$Msg(this.$t("login.codeCheck"));
        }
      }
    },
    register() {
      this.$router.push("/login/register");
    },
    //游客登录
    getGuestLogin() {
      let guestInfo = localStorage.getItem("guestInfo");
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      let params = {
        game_id: this.$store.state.game_id,
        username: JSON.parse(guestInfo)?.username || '',
        device_id: webUUID,
      };
      this.$http.post("/apis/h5/guestLogin", params).then((res) => {
        let guestInfo = {
          username: res.data.username,
          account_type: res.data.account_type,
        };
        localStorage.setItem("guestInfo", JSON.stringify(guestInfo));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userid", res.data.userid);
        localStorage.setItem("iframeURL", res.data.login_url);
        this.$store.state.token = res.data.token;
        this.$store.state.iframeURL = res.data.login_url;
        // this.$router.push("/main");
        this.$router.push("/main/my");
        this.$store.state.sdkShow = false;
        this.$store.commit("openSdk", false);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 8rem;
  width: 7rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  background-color: #fff;
}
.header {
  width: 80%;
  background-color: #fff;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem 0.3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: 0.25rem;
}
.cell {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 0 0.3rem;
  width: 100%;
  height: 0.76rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  box-sizing: border-box;
}
.cell_icon {
  margin-right: 0.2rem;
  width: 0.45rem;
  height: 0.45rem;
}
.cell_input {
  flex: 1;
  overflow: hidden;
}
.cell_input input {
  width: 100%;
  height: 0.4rem;
}
.cell_input input::placeholder {
  color: #8d8d8d;
  font-size: 0.25rem;
}
.login_code {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3rem;
  width: 100%;
}
.login_code .cell {
  flex: 1;
  margin: 0;
  width: auto;
}
.login_code_btn {
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  box-sizing: border-box;
}
.submit_btn {
  margin-top: 0.2rem;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
}
.login_btn {
  margin-top: 0.2rem;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  border: 1px solid $color-primary;
  background-color: #fff;
  color: $color-primary;
  border-radius: 1rem;
  cursor: pointer;
}
.forget_password {
  width: 100%;
  display: flex;
  justify-content: right;
  color: $color-default;
  cursor: pointer;
}
.another_way {
  color: $color-default;
  margin-bottom: 0.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .way_text {
    margin: 0.2rem 0;
  }
}
.way {
  display: flex;
  justify-content: space-around;
  width: 70%;
  .list-action {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      margin: 0 10px;

      a {
        font-size: 0.75rem;
        color: #000;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
        display: block;
        text-decoration: none !important;

        img {
          width: 1.5rem;
          height: 1.5rem;
          border: 0;
          display: block;
          margin: auto;
          margin-bottom: 0.5rem;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
    }
  }
  .way_phone_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: $color-primary;
    overflow: hidden;
    svg {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
    }
  }
  .way_fb_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: #007cf2;
    overflow: hidden;
    svg {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.05rem;
      left: 0.05rem;
    }
  }
}
.read {
  display: flex;
  .checkbox {
    width: 0.3rem;
    height: 0.3rem;
  }
  .read_text {
    color: $color-primary;
  }
}
</style>
