<template>
  <div class="Tabbar">
    <div v-for="(item, index) in tab" @click="tabCheck(index)" :key="index" class="Tabbar_item" :class="{'Tabbar_item_active': item.check}">
      {{item.name}}
    </div>
    <div class="Tabbar_line" :style="`transform: translateX(${100 * checkIndex}%);`">
      <div class="Tabbar_line_widget"></div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return {
      checkIndex: 0
    }
  },
  props: {
    tab: {
      type: Array,
      default: []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  watch: {
    index (val) {
      this.tabCheck(val)
    }
  },
  methods: {
    tabCheck (key) {
      this.checkIndex = key
      this.tab.forEach(item => {item.check = false})
      this.tab[key].check = true
      this.$emit('change', key)
    }
  }
}
</script>
<style scoped lang="scss">
.Tabbar{
  position: relative;
  display: flex; 
  width: 100%;
}
.Tabbar_item{
  width: 50%;
  padding-bottom: .2rem;
  line-height: 1rem;
  text-align: center;
  color: $color-default;
  font-size: .25rem;
  cursor: pointer;
}
.Tabbar_item_active{
  color: $color-primary;
}
.Tabbar_line{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 50%;
  bottom: .2rem;
  transition: .3s;
}
.Tabbar_line_widget{
  width: .7rem;
  height: .1rem;
  background-color: $color-primary;
  border-radius: 1rem;
}
</style>