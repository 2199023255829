import store from '../store/index.js'

function Msg (val) { // 消息通知
	this.$store.commit('messageShow', true)
	this.$store.commit('message', val)
	setTimeout(() => {
		this.$store.commit('messageOpen', true)
	}, 100);
}

function deep (data) { // 深拷贝
  return JSON.parse(JSON.stringify(data))
}

function getUrlParam(name) { // 获取页面地址栏参数
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); 
  var r = window.location.search.substr(1).match(reg);  
  if (r != null) return decodeURI(r[2]); return null;
}

function dateFormat(fmt, dateVal) { // 日期格式化 YYYY-mm-dd HH:MM:SS
	let date = new Date(dateVal.replace(/-/g,"/"))
	let ret;
	const opt = {
		"Y+": date.getFullYear().toString(),        // 年
		"m+": (date.getMonth() + 1).toString(),     // 月
		"d+": date.getDate().toString(),            // 日
		"H+": date.getHours().toString(),           // 时
		"M+": date.getMinutes().toString(),         // 分
		"S+": date.getSeconds().toString()          // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		};
	};
	return fmt;
}

function copyHandle(val){
  const save = function(e) {
    e.clipboardData.setData('text/plain', val)
    e.preventDefault() // 阻止默认行为
  }
  document.addEventListener('copy', save) // 添加一个copy事件
  document.execCommand('copy') // 执行copy方法
}


export default {
	Msg,
  deep,
  getUrlParam,
  dateFormat,
	copyHandle
}
