var isPC = !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
var orientationVal = 'portrait' // 横屏/竖屏 默认竖屏

// 获取自适应属性
window.onresize = getRem;
var viewWidth = 0
function getRem(){
    isPC = !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (orientationVal === 'portrait') {
        viewWidth = document.documentElement.offsetWidth
    } else {
        viewWidth = document.documentElement.offsetHeight
    }
    if (viewWidth > 500) {
        viewWidth = 500
    }
    // pc端视图宽度最大限制为500px
    // if (isPC) {
    //     if (viewWidth > 500) {
    //         viewWidth = 500
    //     }
    //     // setPageMask()
    // } else {
    //     // 切换移动端时，初始化pc的模块
    //     // $('body').attr('style', '')
    //     // $('.w_p_pages_box').remove()
    // }
    // 最小宽度为320px
    if (viewWidth < 320) {
        viewWidth = 320
    }
    document.documentElement.style.fontSize = viewWidth / 7.5+'px';
}
getRem();

getLandscapeStatus()
window.addEventListener("resize", function(event) {
    getLandscapeStatus()
}, false);
function getLandscapeStatus () { // 获取设备横屏状态
    if (isPC) {
        return
    }
  orientationVal =(window.innerWidth > window.innerHeight)? "landscape":"portrait";
  if(orientationVal === 'landscape'){
    // 横屏，添加横屏适配样式
    // $('.bodyM').addClass('bodyM_h')
        getRem()
    } else {
    // 竖屏，去除样式
    // $('.bodyM').removeClass('bodyM_h')
  }
  getRem()
}