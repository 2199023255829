<template>
  <div class="_app">
    <div
      @click="closeSdk"
      class="popup popup_left"
      :class="{ popup_left_on: sdkShow }"
    >
      <!-- <div @click.stop v-if="!token"> <router-view /></div>  -->
      <!-- <div @click.stop class="popup_box" v-else>
        <div class="popup_content _app_box">
          <router-view />
          <div class="_app_box_close">
            <SvgIcon name="back" class="_app_box_close_svg" color="#8d8d8d" />
            <div @click="closeSdk">退出</div>
          </div>
        </div>
      </div> -->
      <router-view />
    </div>

    <iframe
      v-if="token"
      class="iframe"
      :src="iframeURL"
      frameborder="0"
      ref="myIframe"
    ></iframe>
    <img v-else class="iframe" :src="$store.state.initData.bg_url" alt="" />
    <!-- 图标位置 -->
    <van-popup
      v-model:show="show"
      :close-on-click-overlay="false"
      round
      position="bottom"
      :style="{ minHeight: '20%' }"
    >
      <section class="popup-wrap bottom">
        <section class="popup-wrap-header">
          <div class="popup-wrap-close">
            <van-icon name="cross" @click="show = false" />
          </div>
          <div class="popup-wrap-title">{{ $t("main1.payWay") }}</div>
          <div class="popup-wrap-country">
            <!-- <div class="popup-wrap-country-text">
              <div @click="openCountry">
                <span>{{ showCountryText }}</span>
                <van-icon name="arrow-down" />
              </div>
              <van-popup
                v-model:show="showCountry"
                close-on-click-overlay
                :overlay-style="{ background: 'transparent' }"
                class="show-country"
                :style="{
                  position: 'absolute',
                  top: '-1.8rem',
                  maxHeight: '3.5rem',
                  overflow: 'hidden',
                }"
              >
                <div class="popup-wrap-country-list">
                  <div
                    class="popup-wrap-country-item van-hairline--bottom"
                    v-for="(item, index) in country"
                    :key="index"
                    @click="handleCountry(item)"
                  >
                    {{ item.country }}
                  </div>
                </div>
              </van-popup>
            </div> -->
          </div>
        </section>
        <section class="popup-wrap-body">
          <div class="popup-wrap-info">
            <div class="popup-wrap-info-left">
              <span></span>
              <!-- <span :style="{ color: '#938BDB' }">4.99USD星钻</span> -->
            </div>
            <div class="popup-wrap-info-right">
              <span>{{ $t("main1.price") }}:</span
              ><span :style="{ color: '#938BDB' }"
                >{{ productData.order_amt }}VND({{
                  parseInt(productData.order_amt_platform)
                }}Coin)
              </span>
            </div>
          </div>
          <ul class="channel">
            <li
              class="channel-item van-hairline--bottom van-haptics-feedback"
              v-for="(item, index) in productData.newAtm"
              :key="index"
              @click="handleClick(item, index)"
            >
              <!-- <div class="channel-icon">
                <img :src="item.imgsrc" class="channel-icon__img" />
              </div> -->
              <div class="channel-info">
                <span class="channel-info__title">
                  {{ item.name }}
                  <span v-if="!item.pay_type"> ({{ item.remain }} Coin) </span>
                  <span v-if="item.pay_type === 'MCoin'">
                    ({{ item.remain }} Coin)
                  </span>
                </span>
              </div>
              <div class="channel-select">
                <div v-if="!item.no_give_coin">
                  (Tặng thêm
                  {{
                    item.give_coin
                      ? Number(item.give_coin) + productData.give_coin
                      : productData.give_coin
                  }})
                </div>
                <i
                  class="van-badge__wrapper van-icon van-icon-checked channelCheck"
                  :class="checkIndex == index ? 'channelChecked' : ''"
                ></i>
              </div>
            </li>
          </ul>
          <div class="coupon">
            <div style="display: flex; position: relative; height: 0.1rem">
              <input
                type="text"
                @click="openCoupon"
                readonly
                :placeholder="
                  productData.newCoupon && productData.newCoupon.length > 0
                    ? $t('main1.chooseCoupon')
                    : $t('main1.noUseCoupon')
                "
                v-model="couponTitle"
                style="position: absolute"
              />
              <van-icon
                @click="closeCouponTitle"
                v-if="couponTitle"
                name="cross"
                style="position: absolute; right: 0.1rem; top: 0.1rem"
              />
            </div>
            <van-popup
              v-model:show="showCoupon"
              close-on-click-overlay
              :overlay-style="{ background: 'transparent' }"
              class="show-country"
              position="bottom"
              :style="{
                position: 'absolute',
                bottom: '.2rem',
                minHeight: '.5rem',
                maxHeight: '10rem',
                overflow: 'hidden',
              }"
            >
              <div class="popup-wrap-coupon-list">
                <div
                  class="popup-wrap-coupon-item van-hairline--bottom"
                  v-for="(item, index) in productData.newCoupon"
                  :key="index"
                  @click="handleCoupon(item)"
                >
                  {{ item.title }}
                </div>
              </div>
            </van-popup>
          </div>
          <div class="btn">
            <van-button
              style="width: 100%; border-radius: 0.5rem"
              color="linear-gradient(to right, #8E85D6, #AEA6F6)"
              @click="handleConfirm"
              >{{ $t("main1.confirmPay") }}</van-button
            >
          </div>
        </section>
      </section>
    </van-popup>
    <!-- 首次点击支付 -->
    <van-popup
      v-model:show="showPayState"
      round
      :close-on-click-overlay="false"
    >
      <div class="popup-pay-content">
        <div class="popup-pay-info">
          {{ $t("main1.payInProgress") }}
        </div>
        <div class="popup-pay-btn">
          <van-button
            style="width: 45%; border-radius: 0.5rem"
            @click="handleCheckPay"
          >
            {{ $t("main1.EncounterProblems") }}
          </van-button>
          <van-button
            style="width: 45%; border-radius: 0.5rem"
            color="linear-gradient(to right, #8E85D6, #AEA6F6)"
            @click="handleCheckPay"
          >
            {{ $t("main1.PaymentCompleted") }}
          </van-button>
        </div>
      </div>
    </van-popup>
    <!-- 确认支付 -->
    <van-popup v-model:show="showRepay" round :close-on-click-overlay="false">
      <div class="popup-pay-content">
        <div class="popup-pay-info">{{ $t("main1.PaymentNotCompleted") }}</div>
        <div class="popup-pay-btn">
          <van-button
            style="width: 45%; border-radius: 0.5rem"
            @click="handleRecheck"
          >
            {{ $t("main1.Reselect") }}
          </van-button>
          <van-button
            style="width: 45%; border-radius: 0.5rem"
            color="linear-gradient(to right, #8E85D6, #AEA6F6)"
            @click="handleConfirm"
          >
            {{ $t("main1.ContinuePay") }}
          </van-button>
        </div>
      </div>
    </van-popup>
    <Message v-if="messageShow" />
    <FloatBtn @tap="openSdk()" ref="FloatBtn" />
  </div>
</template>
<script>
import { debounce } from "lodash";
import Login from "@/views/login";
import FloatBtn from "@/components/floatBtn";
import Message from "@/components/message.vue";
import md5 from "js-md5";
import { Toast } from "vant";
export default {
  components: { Login, Message, FloatBtn },
  data() {
    return {
      show: false,
      showPayState: false,
      showRepay: false,
      radio: "1",
      checkIndex: 0,
      couponTitle: "",
      showCountry: false,
      showCoupon: false,
      backOrderNo: "", //支付返回的orderNo
      productData: {
        newCoupon: [],
      }, //接口产品信息
      eventData: {}, //cp event信息
      confirmData: {}, //支付信息
      country: [
        {
          country: "越南",
          value: "VN",
        },
        {
          country: "泰国",
          value: "TH",
        },
        {
          country: "印尼",
          value: "ID",
        },
        {
          country: "菲律宾",
          value: "PH",
        },
      ],
      showCountryText: "越南",
      showCountryValue: "VN",
    };
  },
  computed: {
    messageShow() {
      return this.$store.getters.messageShow;
    },
    token() {
      return this.$store.state.token;
    },
    iframeURL() {
      return this.$store.state.iframeURL;
    },
    param() {
      // 假设我们要获取名为"id"的参数
      return this.$store.state.game_id;
    },
    sdkShow() {
      return this.$store.state.sdkShow;
    },
  },
  watch: {
    param(newVal, oldVal) {
      if (newVal) {
        this.getInit();
      }
    },
  },
  created() {
    const webUUID = Adjust.getWebUUID();
    this.$store.state.webUUID = webUUID;
    this.$store.state.token = localStorage.getItem("token");
    this.$store.state.iframeURL = localStorage.getItem("iframeURL");
    window.addEventListener("message", this.receiveMessage);
    this.$store.state.game_id = localStorage.getItem("game_id");
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("game_id") &&
      localStorage.getItem("iframeURL")
    ) {
      this.$router.push("/main/my");
    }
  },
  mounted() {
    // 在父级页面中监听来自子页面的消息
    if (!localStorage.getItem("token")) {
      this.openSdk();
    }
  },
  methods: {
    openSdk() {
      this.$store.state.sdkShow = true;
      this.$store.commit("openSdk", true);
    },
    closeSdk() {
      let routeName = ["login", "loginSetPassword", "register"];
      if (routeName.includes(this.$route.name)) {
        return;
      }
      this.$store.state.sdkShow = false;
      this.$store.commit("openSdk", false);
    },
    getInit() {
      let params = {
        game_id: this.param,
      };
      this.$http.post("apis/h5/init", params).then((res) => {
        if (res.code === 200) {
          this.$store.state.initData = res.data;
          this.$store.state.fb_appid = res.data.facebook_appid;
          this.$store.state.client_id = res.data.google_client_id;
          let gv_img = {
            0: require("@/assets/logo.png"),
            1: require("@/assets/mocLogo.png"),
            2: require("@/assets/mgolLogo.png"),
          };
          this.$refs.FloatBtn.imgSrc = gv_img[res.data.gv_checked] || gv_img[0];
        } else {
          if (res.code !== 206) {
            this.$Msg(res.msg);
          }
        }
      });
    },
    signData(data, gameKey) {
      const sortedKeys = Object.keys(data).sort();
      const tmp = sortedKeys.map((key) => `${key}=${data[key]}`);
      const str = tmp.join("&") + gameKey;
      return md5(str);
    },
    receiveMessage(event) {
      //区服上报
      if (event.data.cp_sname) {
        let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
        let params = {
          ...event.data,
          // game_id: this.$store.state.game_id,
          userid: localStorage.getItem("userid"),
          token: localStorage.getItem("token"),
          device_id: webUUID,
        };
        this.$http.post("apis/h5/enterGame", params).then((res) => {
          if (res.code === 200) {
            //调用vuex的赋值 可能加个storage
            // console.log(res.data);
            // this.$store.state.iframeURL = "https://www.yysls.cn/m/?from=nietop";
            if (res.data.isFirstRole) {
              switch (Number(params.game_id)) {
                case 121:
                  Adjust.trackEvent({
                    eventToken: "nt0ym8",
                  });
                  break;
                default:
                  Adjust.trackEvent({
                    eventToken: "vn1e5g",
                  });
                  break;
              }
            }
            // this.$Msg(res.msg);
            // this.$Msg(this.$t("main1.reportSuccessfully"));
          } else {
            if (res.code !== 206) {
              this.$Msg(res.msg);
            }
          }
        });
      }
      //支付
      if (event.data.product_id) {
        Toast.loading({
          duration: 0,
          message: this.$t("main1.loading"),
          forbidClick: true,
        });
        let params = {
          ...event.data,
          game_id: this.param,
        };
        this.eventData = params;
        this.getpayDetail();
      }
      //开始新手任务
      if (event.data.startNew) {
        this.startNew();
      }
      //完成新手任务
      if (event.data.endNew) {
        this.endNew();
      }
    },
    startNew() {
      Adjust.trackEvent({
        eventToken: "85am31",
      });
    },
    endNew() {
      Adjust.trackEvent({
        eventToken: "ryujoe",
      });
    },
    getpayDetail() {
      this.$http.post(`/apispay/h5pay/detail`, this.eventData).then((res) => {
        if (res.code === 200) {
          this.show = true;
          this.productData = res.data;
          let coin = {
            name: "Coin",
            remain: this.productData.coin,
            pay_type: "",
            no_give_coin: true,
          };
          let MCoin = {
            name: "MCoin",
            remain: this.productData.m_coin,
            pay_type: "MCoin",
            no_give_coin: true,
          };
          let newAtm = [];
          newAtm.push(coin, MCoin, ...this.productData.recommend_atm);
          let give_coin = parseInt(
            res.data.order_amt * (res.data.extra_coin / 100)
          );
          this.productData.newAtm = newAtm;
          this.productData.give_coin = give_coin;
          this.handleClick(this.productData.newAtm[2], 2);
          Toast.clear();
        } else {
          if (res.code != 206) {
            this.$Msg(res.msg);
          }
          Toast.clear();
        }
      });
    },
    //切换选择支付方式
    handleClick(data, index) {
      if (
        data.name === "Coin" &&
        Number(this.productData.order_amt_platform) > data.remain
      ) {
        this.$Msg(
          this.$t("main1.minPay") + this.productData.order_amt_platform
        );
        return;
      }
      if (
        data.name === "MCoin" &&
        Number(this.productData.order_amt) > data.remain
      ) {
        this.$Msg(this.$t("main1.minPay") + this.productData.order_amt);
        return;
      }
      if (this.productData.order_amt < data.min) {
        this.$Msg(this.$t("main1.minPay") + data.min);
        return;
      }
      if (this.productData.order_amt > data.max) {
        this.$Msg(this.$t("main1.maxPay") + data.max);
        return;
      }
      if (data.maintain) {
        this.$Msg("Đang bảo trì, xin chọn phương thức thanh toán khác");
        return
      }
      this.checkIndex = index;
      this.confirmData = { ...this.eventData, ...data };
      let newCoupon = [];
      this.productData.coupon.forEach((item) => {
        if (item.channel_type_arr.includes(this.confirmData.name)) {
          newCoupon.push(item);
        }
      });
      this.productData.newCoupon = newCoupon;
      this.closeCouponTitle();
    },
    //打开优惠券弹框
    openCoupon() {
      if (
        this.productData.newCoupon &&
        this.productData.newCoupon.length == 0
      ) {
        return;
      }
      this.showCoupon = true;
    },
    openCountry() {
      this.showCountry = true;
    },
    handleCountry(item) {
      Toast.loading({
        duration: 0,
        message: this.$t("main1.loading"),
        forbidClick: true,
      });
      this.showCountry = false;
      this.showCountryText = item.country;
      this.showCountryValue = item.value;
      this.closeCouponTitle();
      this.getpayDetail();
    },
    handleCoupon(item) {
      if (!item.channel_type_arr.includes(this.confirmData.name)) {
        let channel_type_arr = item.channel_type_arr.toString().split(",");
        this.$Msg(this.$t("main1.onlyUseCoupon", { coupon: channel_type_arr }));
        return;
      }
      if (item.is_get === "0") {
        let params = {
          // userId: localStorage.getItem("userid"),
          userid: this.confirmData.userid,
          coupon_id: item.coupon_id,
        };
        this.$http.post(`/apispay/h5pay/getCoupon`, params).then((res) => {
          if (res.code === 200) {
            item.is_get === "0";
            this.couponTitle = item.title;
            this.confirmData.user_coupon_id = res.data.user_coupon_id;
            this.showCoupon = false;
          }
        });
      } else {
        this.couponTitle = item.title;
        this.confirmData.user_coupon_id = item.user_coupon_id;
        this.showCoupon = false;
      }
    },
    closeCouponTitle() {
      this.couponTitle = "";
      this.confirmData.user_coupon_id = "";
    },
    //首次确认是否支付完成
    handleCheckPay() {
      let params = {
        order_no: this.backOrderNo,
      };
      this.$http.post(`/apispay/h5pay/queryOrder`, params).then((res) => {
        if (res.code === 200) {
          if (res.data.pay_status === "0") {
            //未支付
            this.showPayState = false;
            this.showRepay = true;
          } else {
            this.showPayState = false;
            this.show = false;
          }
        } else {
          this.showPayState = false;
          this.showRepay = true;
        }
      });
    },
    //重新选择支付方式
    handleRecheck() {
      this.showRepay = false;
    },
    handleConfirm() {
      var winOpen = "";
      if (this.confirmData.pay_type && this.confirmData.pay_type !== "MCoin") {
        winOpen = window.open("", "_blank");
      }
      this.confirm(winOpen);
    },
    //确认支付
    confirm: debounce(function (winOpen) {
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      const {
        game_id,
        product_id,
        userid,
        orderno,
        order_no,
        server_id,
        cp_role_id,
        extra,
        sign,
        user_coupon_id,
        pay_type,
      } = this.confirmData;
      let params = {
        game_id,
        product_id,
        userid,
        orderno,
        order_no,
        server_id,
        cp_role_id,
        extra,
        sign,
        user_coupon_id,
        pay_type,
        device_id: webUUID,
      };
      this.showRepay = false;
      this.$http.post(`/apispay/h5pay/pay`, params).then((res) => {
        if (res.code === 200) {
          if (res.data.redirect_url) {
            if (!params.order_no) {
              this.showPayState = true;
            }
            this.backOrderNo = res.data.order_no;
            if (res.data.redirect_type === 2) {
              const href = this.$router.resolve({
                path: "/payCode",
                query: {
                  redirect_url: res.data.redirect_url,
                  order_no: res.data.order_no,
                  type: res.data.type,
                },
              }).href;
              if (!params.order_no) {
                this.show = false;
              }
              window.open(href, "_blank");
            } else {
              // 创建一个新的<a>元素
              // var link = document.createElement("a");
              // link.href = res.data.redirect_url;
              // link.target = "_blank"; // 在新标签页中打开链接
              // link.rel = 'noopener noreferrer';
              // // 模拟用户点击
              // document.body.appendChild(link);
              // link.click();
              // // 移除链接元素
              // document.body.removeChild(link);
              if (params.order_no) {
                this.show = false;
              }
              winOpen.location = res.data.redirect_url;
              // window.open(res.data.redirect_url, "_blank");
            }
            // window.location.href = res.data.redirect_url;
          } else {
            this.show = false;
            this.$Msg("Nạp tiền thành công");
          }
        } else {
          winOpen.close();
          if (res.code != 206) {
            this.$Msg(res.msg);
          }
        }
      });
    }, 1000),
  },
};
</script>
<style>
._app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
._app_box {
  width: 6rem;
  height: 100%;
  background-color: #fff;
}
._app_box_close {
  position: absolute;
  right: calc(-1rem);
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background-color: #fff;
  color: #8d8d8d;
  font-size: 0.3rem;
  border-radius: 0 0.3rem 0.3rem 0;
}
._app_box_close_svg {
  width: 0.5rem;
  height: 0.5rem;
  transform: rotateZ(180deg);
}
.iframe {
  width: 100%;
  height: 100%;
  max-width: 13rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.popup-wrap {
  padding: 0.5rem;
}
.popup-wrap-header {
  display: flex;
  justify-content: space-between;
}
.popup-wrap-close {
  width: calc(100% / 3);
}
.popup-wrap-title {
  font-weight: bold;
  width: calc(100% / 3);
  text-align: center;
}
.popup-wrap-country {
  position: relative;
  width: calc(100% / 3);
  text-align: right;
}
.popup-wrap-country-text {
  position: relative;
}
.popup-wrap-country-list {
  height: 3.2rem;
  width: 2rem;
  text-align: center;
}
.popup-wrap-country-item {
  padding: 0.2rem 0;
}
.show-country {
  /* top: 22% !important;
  left: 50% !important; */
  border-radius: 0.5rem;
  padding: 0 0.3rem;
  box-shadow: 0 0.0533333rem 0.32rem rgba(50, 50, 51, 0.12);
}
#my-container {
  position: absolute;
  top: -3.2rem;
  left: -28%;
  height: 3.2rem;
  width: 3rem;
}

.popup-wrap-info {
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0;
  font-size: 12px;
}
.channel {
  background: #f1f1f1;
  border-radius: 0.2666667rem;
  padding: 0 0.2rem;
  max-height: 50vh;
  overflow-y: auto;
}
.channel .channel-item {
  padding: 0.3rem 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.channel .channel-icon {
  width: 0.6rem;
  height: 0.6rem;
  flex-shrink: 0;
  position: relative;
}
.channel .channel-icon__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.channel .channel-info {
  flex: 1;
  padding: 0 0.2133333rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
}
.channel .channel-info__title {
  font-size: 0.28rem;
  color: #3b3b3b;
}
.channel .channel-select {
  width: 3rem;
  font-size: 0.3rem;
  flex-shrink: 0;
  display: flex;
  justify-content: right;
}
.channel .channel-select div {
  font-size: 0.25rem;
  margin-right: 0.1rem;
}
.channelCheck {
  color: rgb(209, 209, 209);
  font-size: 1em;
}
.channelChecked {
  color: rgb(147, 139, 219);
}
.btn {
  width: 100%;
  margin-top: 0.5rem;
}
.coupon {
  position: relative;
  margin: 0.3rem 0 0.8rem;
  width: 100%;
}
.coupon input {
  width: 100%;
  height: 0.5rem;
  border: 1px solid #f1f1f1;
  border-radius: 0.1rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
}
.van-popup {
  overflow-y: visible !important;
}
.coupon .van-popup {
  overflow-y: auto !important;
  height: auto;
  width: 100%;
  box-sizing: border-box;
}
.popup-wrap-coupon-list {
  text-align: center;
}
.popup-wrap-coupon-item {
  padding: 0.2rem;
}
.popup-pay-content {
  box-sizing: border-box;
  width: 6rem;
  padding: 0.5rem;
  text-align: center;
}
.popup-pay-info {
  margin-bottom: 0.5rem;
}
.popup-pay-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
