// 对应中文翻译看_ch.js

module.exports = {
  // 按钮
  btn: {
    confirm: 'Xác Nhận',
    cancel: 'Hủy Bỏ',
    close: 'Đóng',
    back: 'trở lại'
  },
  // 登录页
  login: {
    email: 'Email',
    emailPlacehold: 'please input email',
    phone: 'Phone',
    phonePlacehold: 'please input phone',
    password: 'Password',
    passwordPlacehold: 'please input password',
    signIn: 'Log in',
  },
  // tabbar 
  tabbar: {
    t1: 'Nạp tiền',
    t2: 'Tiền khóa',
    t3: 'Của tôi'
  },
  // 选择游戏
  game: {
    gameTitle: 'Danh sách game'
  },
  // 消费页（主页）
  consume: {
    bindServeTitle: 'Chọn máy chủ',
    bindRoleTitle: 'Chọn nhân vật',
    bindGiftTitle: 'Loại lễ bao',
    bindDefault: 'xin vui lòng chọn'
  },
  // 个人中心
  center: {
    username: 'Tài khoản',
    coin: 'Tiền trong ví',
    bind_coin: 'Coin khoá',
    task: 'Nhiệm vụ',
    coupon: 'Voucher',
    unblock: 'Mở khoá',
    tabGame: 'Chọn game',
    logOut: 'Đăng xuất'
  },
  // gate
  // gate: {
  //   serial: 'Số serial',
  //   serialDefault: 'Nhập serial thẻ',
  //   IDCard: 'Mã thẻ',
  //   IDCardDefault: 'Nhập mã thẻ',
  //   submit: 'Nạp thẻ'
  // },
  // 充值
  recharge: {
    telCard: 'Thẻ Điện Thoại',
    gate: 'Gate'
  },
  // 计费点
  product: {
    pay: 'Đồng ý mua',
    coupon: 'Voucher',
    payType: 'Phương thức thanh toán',
    coin: 'coin',
    otherPayment: 'other payment',
    recharge: 'Nạp tiền',
    couponDisabled1: 'Chưa có có thể dùng',
    couponDisabled2: 'Phương thức thanh toán này không thể dùng voucher',
    payTypeDisabledInf1: 'Số tiền không đủ để mua gói',
    payTypeDisabledInf2: 'Voucher không hỗ trợ phương thức thanh toán này',
    payTypeDefault: 'xin vui lòng chọn'
  },
  // 优惠券
  coupon: {
    effectiveTime: 'Thời gian hiệu lực',
    timeAvailable: 'Thời gian có sẵn',
    deliver: 'Tặng',
    reduce: 'Giảm',
    paymentChannel: 'Kênh thanh toán',
    remainingNum: 'lần còn lại',
    maxTitle: 'Đủ',
    getCoupon: 'Nhận'
  },
  // 任务中心
  task: {
    content1: 'Trong sự kiện thông qua webpay tiến hành nạp {val}VNĐ , nhận vé ưu đãi',
    content2: 'Trong sự kiện thông qua webpay tiến hành nạp đến mốc chỉ định , nhận vé ưu đãi',
    content3: 'Trong sự kiện thông qua webpay tiến hành nạp, nhận vé ưu đãi',
    content4: 'Trong sự kiện người chơi tải và đăng nhập thành công {game} , nhận vé ưu đãi',
    title_description: 'Giúp đỡ',
    remark: 'Note : Sử dụng vé ưu đãi và dùng coin nạp sẽ không tính vào nhiệm vụ',
    unacommpolished: 'Chưa đạt',
    received: 'Đã nhận',
    receivedSuccess: 'Nhận thành công',
    receivedErr: 'Nhận thất bại , Hãy liên hệ CSKH',
    availableChannels: 'Dùng cho',
    time_title: 'Kết thúc còn',
    time_d: 'Ngày',
    time_h: 'H',
    time_m: 'Phút',
    time_s: 'S',
    accum1: 'Đã nạp <span>{val}</span> ngày',
    accum2: 'Đã nạp <span>{val}</span> VND',
    accum4: 'Đã đăng nhập <span>{val}</span> ngày',
    childAccum1: 'Ngày thứ {val}',
    childAccum2: 'Tích luỹ nạp {val}',
    childAccum3: 'Nạp đơn từ {min} VNĐ - {max} VNĐ',
    childAccum4: 'Đăng nhập ngày {val}',
    reward: 'Thưởng',
    btn1: 'Nhận',
    btn2: 'Đã nhận',
    btn3: 'Chưa đạt',
    instruction: 'Giúp đỡ',
    couponDate: 'Hiệu lực trong {day} ngày sau khi nhận'
  },
  login: {
    accountPlaceholder: 'Nhập tài khoản',
    passwordPlaceholder: 'Nhập mật khẩu',
    forgetPassword: 'Quên mật khẩu?',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    other: 'Phương thức đăng nhập khác',
    emailPlaceholder: 'Nhập Email',
    codePlaceholder: 'Nhập OTP',
    getCode: 'Nhận OTP',
    accountLogin: 'Đăng nhập tài khoản',
    codeLogin: 'Đăng nhập OTP',
    emailCheck: 'Bạn chưa điền Email!',
    accountCheck: 'Tài khoản hoặc mật khẩu không đúng',
    codeCheck: 'Vui lòng nhập OTP chính xác',
    newPassword: 'Mật khẩu mới',
    repeatPassword: 'Xác nhận mật khẩu mới',
    registerSuccess: 'Đăng ký thành công',
    differencePassword: 'Xác nhận mật khẩu mới không trùng khớp',
    noData: 'Vui lòng điền đầy đủ thông tin',
    changePassword: 'Đổi mật khẩu',
    PasswordSuccess: 'Đổi thành công',
    install:'Hướng dẫn cài đặt',
    bind:'Liên kết tài khoản',
    otherBind:'Cách liên kết khác',
  },
  main: {
    my: 'Tôi',
    exit: 'Thoát',
  },
  main1: {
    payWay: 'Phương thức thanh toán',
    price: 'Giá',
    chooseCoupon: 'Xin chọn Voucher',
    noUseCoupon: 'Không có Voucher',
    confirmPay: 'Xác nhận thanh toán',
    payInProgress: 'Đang thanh toán, chọn "Hoàn tất thanh toán" có thể làm mới và kiểm tra trạng thái đơn hàng',
    EncounterProblems: 'Vấn đề gặp phải',
    PaymentCompleted: 'Hoàn tất thanh toán',
    PaymentNotCompleted: 'Chưa hoàn tất thanh toán',
    Reselect: 'Chọn lại',
    ContinuePay: 'Tiếp tục thanh toán',
    reportSuccessfully: 'Báo cáo thành công',
    loading: 'Đang tải dữ liệu...',
    minPay: 'Số tiền thanh toán tối thiểu ',
    maxPay: 'Số tiền thanh toán tối đa ',
    onlyUseCoupon: 'Voucher này chỉ có thể thanh toán bằng {coupon}',
  },
  gate: {
    serialDefault: 'Nhập số thẻ',
    passwordDefault: 'Nhập mã thẻ',
    submit: 'Nạp',
    submitSuccess: 'Nạp thành công',
    noData: 'Vui lòng điền đầy đủ thông tin',
  },
}