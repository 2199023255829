<template>
  <div class="NoData">
    <div>{{title}}</div>
  </div>
</template>
<script>

export default {
  data(){
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
  },
  methods: {
  }
}
</script>
<style scoped>
.NoData{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: .8rem;
  font-size: .25rem;
  color: #8d8d8d;
}
</style>